import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import '../assets/styles/pages/quote/index.scss';

import Layout from '../components/layout';
import SEO from '../components/seo';

const QuoteIndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      guidelineOne: file(relativePath: { eq: "quote/guideline-one.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      guidelineTwo: file(relativePath: { eq: "quote/guideline-two.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      guidelineThree: file(relativePath: { eq: "quote/guideline-three.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout wrapperClass="quote-container" hideHeaderFooter={true}>
      <SEO title="DingGo | Car Quote - Submit Your Snap" description="Upload your photos and describe the damage to compare car repair quotes online, fast and free. Snap. Compare. Repair." />
      <div className="quote-wrapper">
        <div className="container xs">
          <h1>Do you have photos? </h1>
          <p className="intro">
            We need <strong>3 clear photos</strong> of the damaged area of your car. You don't need to be a pro but please follow the guides below.
          </p>
          <h2>Photo Guidelines</h2>
          <div className="photo-guidelines-wrapper">
            <div className="photo-guideline">
              <div className="image-wrapper">
                <Img fluid={data.guidelineOne.childImageSharp.fluid} />
              </div>
              <div className="text-wrapper">
                <span className="alert">Most Important</span>
                <h3>1. Damage Including Surrounding Panels</h3>
                <p>From 4 paces away. Make sure the surrounding parts of the damaged area are visible</p>
              </div>
            </div>
            <div className="photo-guideline">
              <div className="image-wrapper">
                <Img fluid={data.guidelineTwo.childImageSharp.fluid} />
              </div>
              <div className="text-wrapper">
                <h3>2. Damage Up Close and Front On</h3>
                <p>Standing 1 pace away take a front on photo of the damaged area</p>
              </div>
            </div>
            <div className="photo-guideline">
              <div className="image-wrapper">
                <Img fluid={data.guidelineThree.childImageSharp.fluid} />
              </div>
              <div className="text-wrapper">
                <h3>3. Damage From An Angle</h3>
                <p>Standing 1 pace away take a photo of the damaged area from an angle</p>
              </div>
            </div>
          </div>
          <div className="btn-wrapper">
            <Link to="/quote/step-one" className="btn" id="button-click-to-step-1">
              Yes. I have 3 clear photos
            </Link>
            <Link to="/quote/step-two" className="btn btn-outline" id="button-click-to-step-2">
              No, Skip for now
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default QuoteIndexPage;
